import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StorageKeys, StorageService } from '@revolve-app/app/core/utils/storage.service';

@Directive({
    standalone: true,
    selector: '[hasRole]'
})
export class PermissionBasedDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    @Input() set hasRole(roles: string[]) {
        if (roles && roles.indexOf(StorageService?.getJsonItem(StorageKeys.SESSION)?.role) !== -1) {  // Replace this with your actual auth service function to check roles
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else if(roles[0] === 'guest'){
          this.viewContainer.createEmbeddedView(this.templateRef);
        }else {
            this.viewContainer.clear();
        }
    }

}
